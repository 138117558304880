export const calendarEnFormats = {
  sameDay: "hh:mm A [Today]",
  nextDay: "hh:mm A [Tomorrow]",
  nextWeek: "DD/MM/YYYY hh:mm A",
  lastDay: "hh:mm A [Yesterday]",
  lastWeek: "DD/MM/YYYY hh:mm A",
  sameElse: "DD/MM/YYYY hh:mm A",
};

export const calendarFrFormats = {
  sameDay: "HH:mm [Aujourd'hui]",
  nextDay: "HH:mm [Demain]",
  nextWeek: "DD/MM/YYYY HH:mm",
  lastDay: "HH:mm [Hier]",
  lastWeek: "DD/MM/YYYY HH:mm",
  sameElse: "DD/MM/YYYY HH:mm",
};

export const calendarRuFormats = {
  sameDay: "hh:mm A [Сегодня]",
  nextDay: "hh:mm A [Завтра]",
  nextWeek: "DD/MM/YYYY hh:mm A",
  lastDay: "hh:mm A [Вчера]",
  lastWeek: "DD/MM/YYYY hh:mm A",
  sameElse: "DD/MM/YYYY hh:mm A",
};