{
  "atNextDelivery": "at next delivery",
  "averageNext6M": "average next 6M",
  "next1YAvg": "next 1Y avg.",
  "avgStockMinCoverageNext6M": "Stock Min (days)",
  "avgStockMinQtyNext6M": "Stock Min (qty)",
  "mae": "F. Forecast MAE",
  "maeExplanation": "Absolute error between flowlity forecast and past demand over the last {count} days: ABS(demand - forecast) in qty",
  "mape": "F. Forecast MAPE",
  "mapeExplanation": "Mean average percentage error between flowlity forecast and past demand over the last {count} days: ABS(demand - forecast)/demand (in %)",
  "forecastValueAdded": "Forecast Value Added",
  "forecastValueAddedExplanation": "Flowlity forecast against statistical forecast (Rolling Average of the previous three months), when the value is positive the flowlity forecast is better by X%",
  "bufferLevel": "Buffer Level",
  "current": "current",
  "currentMonth": "current month",
  "Customers": "Customers",
  "daysShort": "d",
  "DefaultUnit": "Default Unit",
  "demand": "Demand",
  "EndOfLife": "End of Life",
  "FixingRecommendationsDate": "Fixed Recommendations",
  "FixingRecommendationsDateSubtitle": "Until",
  "Events": "Events",
  "Promotions": "Promotions",
  "FDemand": "F. Demand",
  "FForecast": "F. Forecast",
  "firmOrders": "Firm Orders",
  "forecastSource": "Forecast source",
  "forecastSourceMyForecast": "My forecast",
  "forecastSourceFlowlity": "Flowlity forecast",
  "forecastSourceMrp": "External forecast",
  "FinalForecastAgainstPastDemand": "next 30d trend",
  "FinalForecastNext30DaysCur": "next 30d, €",
  "FinalForecastNext30DaysQty": "next 30d, qty",
  "FinalForecastNext180DaysQty": "next 6M, qty",
  "FinalForecastNext360DaysQty": "next 1Y, qty",
  "flowlityAi": "Flowlity AI",
  "myStockMin": "My Stock Min",
  "FutureDemandNext30DaysCur": "next 30d, €",
  "FutureDemandNext30DaysQty": "next 30d, qty",
  "inventoryStrategy": "Inventory Strategy",
  "InventoryValue": "Inventory Value",
  "initialValue": "Initial Value",
  "last3M": "last 3M",
  "last6M": "last 6M",
  "lastMonth": "last month",
  "LeadTime": "Lead Time",
  "mixed": "Mixed",
  "nextDeliveryDate": "Next Delivery Date",
  "none": "None",
  "PastDemandLast30DaysCur": "last 30d, €",
  "PastDemandLast30DaysQty": "last 30d, qty",
  "PastDemandLast180DaysQty": "last 6M, qty",
  "PastDemandLast360DaysQty": "last 1Y, qty",
  "PastDemandLast30DaysTrend": "last 30d trend",
  "PDemand": "P. Demand",
  "ProductName": "Product Name",
  "Reference": "Reference",
  "reorderPoint": "ROP",
  "ropAI": "ROP + AI",
  "reviewDemand": "Demand Review",
  "reviewPlanning": "Planning Review",
  "ShelfLife": "Shelf Life",
  "SimilarProducts": "Similar Products",
  "StockCoverage": "Stock Coverage",
  "stockCoverageAtNextDeliveryTooltip": "Stock coverage in days on the next delivery (firm or planned)",
  "stockCoverageCurrentMonthTooltip": "Stock coverage in days - average for current month",
  "stockCoverageNext6MonthsTooltip": "Stock coverage in days - average for the next 6 months",
  "stockCoverageNext1YAvgTooltip": "Stock coverage in days - average for the next year",
  "StockQty": "Stock Qty",
  "subContracted": "With Components",
  "Suppliers": "Suppliers",
  "SupplyBufferPolicy": "Supply",
  "SupplyBufferPolicySubtitle": "Buffer Policy",
  "TagName": "Tag Name",
  "Tags": "Tags | Tag | Tags",
  "ZeroStockDays": "Zero Stock Days",
  "zeroStockDaysLast6MonthsTooltip": "Average percentage of days without stock over the last 6 months",
  "zeroStockDaysNext1YearTooltip": "Average percentage of days without stock over the next year",
  "zeroStockDaysLastMonthTooltip": "Average percentage of days without stock over the last month",
  "forecastModelDemandType": "Demand Type",
  "demandPredictability": "Predictability",
  "demandPredictabilitySubtitle": "of demand",
  "demandPredictabilityTooltip": "Demand predictability is the quality of past demand data which is being used for generating the forecast",
  "demandPredictabilityNoTooltip": "No past demand data, cannot generate any forecast",
  "demandPredictabilityLowTooltip": "Past demand data is limited and does not allow generating the best forecast",
  "demandPredictabilityMediumTooltip": "Flowlity provides the best forecast, however, demand demonstrated an erratic behavior",
  "demandPredictabilityHighTooltip": "Flowlity provides the best forecast"
}