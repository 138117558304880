{
  "en": {
    "dataJobHeader": {
      "dataWarningAlmostDone": "The data is being updated... It's taking a little longer today, but it will be done in a few minutes.",
      "dataWarningHour": "more than one hour.",
      "dataWarningMain": "The data is being updated... It will take ",
      "dataWarningMinutes": "{count} more minute. | {count} more minute. | {count} more minutes.",
      "dataWarningSubItem": "You can continue working, however the data can be obsolete.",
      "dataWarningThirtyMinutes": "more than 30 minutes."
    },
    "lineItem": {
      "aboveFlowlityMax": "Too much inventory",
      "allGood": "All good",
      "belowFlowlityMin": "Below Recommended Minimum",
      "coverageDays": "Your current stock could cover {number} day(s)",
      "day": "D",
      "flowlitySupply": "Recommendation by Flowlity",
      "fullyFrozen": "Period fully frozen",
      "lotSize": "Lot size",
      "moq": "MOQ",
      "outOfStock": "Out of stock",
      "partlyFrozen": "Period partly frozen, switch to days to edit this supply",
      "validatedTooltip": "Warning, the order for this supply has been validated"
    },
    "homeAccuracyChart": {
      "accuracy": "Forecast accuracy",
      "accuracyTips": "Calculated on Flowlity forecast on different horizons. The Aggregated MAPE compares the cumulated forecast and the cumulated actual demand. The Weighted MAPE gives a different weight to each product based on its actual demand",
      "aggregated": "Aggregated",
      "average": "Average",
      "in": "in",
      "mapeExplanation": "MAPE defines the average absolute difference in between the forecast and the actuals. The closer to zero the MAPE is, the better the forecast.",
      "month": "month | months",
      "noData": "No data",
      "weight": "Weighted",
      "whatMAPE": "What is the MAPE?"
    },
    "homeSummary": {
      "allStock": "all stock",
      "days": "days",
      "daysOfCoverage": "Stock coverage",
      "noQuantityTips": "There are multiple default units of measures for this scope. Aggregating quantities is not possible.",
      "quantityLabel": "Quantity",
      "trendsHistoryTips": "Stock level is displayed end of month in quantity and in value; Stock coverage represents the number of days of stock taking in account the forecast, at month end",
      "trendsHistoryValue": "Inventory Evolution (history and forecast)",
      "valueLabel": "Value",
      "valueLegend": "Inventory value",
      "inPercentage": "in %",
      "inDays": "in days",
      "demand_plan": "Demand plan",
      "demandPlanExplanation": "Total final forecast, available in value and quantity",
      "inventory_level": "Inventory level",
      "inventoryLevelExplanation": "Stock level is displayed end of month and available in value and in quantity",
      "stock_coverage": "Stock coverage",
      "stockCoverageExplanation": "Calculated on the inventory at month end and the final forecast",
      "zero_stock_days": "Zero stock days",
      "zeroStockExplanation": "Average percentage of days without stock on MTS products",
      "supply_plan": "Supply plan",
      "supplyPlanExplanation": "Sum of firm and projected supply orders, in value and quantity"
    },
    "homeOutOfStock": {
      "avgDays": "In %",
      "noData": "No data",
      "stockouts": "Zero stock days",
      "stockoutsTips": "Average percentage of days without stock."
    },
    "notFound": {
      "home": "to the home",
      "statement": "Oops, sorry, it seems that this page does not exist.",
      "suggestion": "Maybe you would like to go "
    }
  },
  "fr": {
    "dataJobHeader": {
      "dataWarningAlmostDone": "Les données sont en train d'être mises à jour... Cela prend un peu plus de temps aujourd'hui, veuillez patienter encore quelques minutes.",
      "dataWarningHour": "plus d'une heure.",
      "dataWarningMain": "Les données sont en train d'être mises à jour... Cela devrait prendre ",
      "dataWarningMinutes": "encore {count} minute. | encore {count} minute. | encore {count} minutes.",
      "dataWarningSubItem": "Vous pouvez continuer de travailler, mais les données sont potentiellement obsolètes.",
      "dataWarningThirtyMinutes": "plus de 30 minutes."
    },
    "lineItem": {
      "aboveFlowlityMax": "Stock au dessus du maximum recommandé",
      "allGood": "Ok",
      "belowFlowlityMin": "Stock en dessous du minimum recommandé",
      "coverageDays": "Votre stock actuel pourrait couvrir {number} jour(s)",
      "day": "J",
      "flowlitySupply": "Recommandation par Flowlity",
      "fullyFrozen": "Période entièrement frozen",
      "lotSize": "Taille de lot",
      "moq": "Mini de commande",
      "outOfStock": "Rupture de stock",
      "partlyFrozen": "La période est partiellement gelée, éditez cette supply en jour",
      "validatedTooltip": "Attention, la commande pour cette supply a été validée"
    },
    "homeAccuracyChart": {
      "accuracy": "Précision de la prédiction",
      "accuracyTips": "Calculé sur la prévision Flowlity à différents horizons. La MAPE agrégée compare la prévision cumulée et la demande réelle cumulée. La MAPE pondérée attribue un poids différent à chaque produit en fonction de sa demande réelle",
      "aggregated": "Agrégée",
      "average": "Moyen",
      "in": "en",
      "mapeExplanation": "MAPE définit la différence absolue moyenne entre les prévisions et les chiffres réels. Plus le MAPE est proche de zéro, meilleures sont les prévisions.",
      "month": "mois | mois",
      "noData": "Pas de données",
      "weight": "Pondérée",
      "whatMAPE": "Qu'est-ce que la MAPE?"
    },
    "homeSummary": {
      "allStock": "stock global",
      "days": "jours",
      "daysOfCoverage": "Couverture de stock",
      "noQuantityTips": "Il existe plusieurs unités de mesure par défaut pour cette sélection. L'agrégation des quantités n'est pas possible.",
      "quantityLabel": "Quantité",
      "trendsHistoryTips": "Niveau de stock fin de mois en quantité et valeur; Couverture de stock représente le nombre de jours de stock en fonction des prévisions de demande",
      "trendsHistoryValue": "Évolution du stock (historique et prévision)",
      "valueLabel": "Valeur",
      "valueLegend": "Valeur de stock",
      "inPercentage": "en %",
      "inDays": "en jours",
      "demand_plan": "Plan de demande",
      "demandPlanExplanation": "Prévision finale totale, disponible en valeur et en quantité",
      "inventory_level": "Niveau de stock",
      "inventoryLevelExplanation": "Le niveau de stock est affiché en fin de mois et disponible en valeur et en quantité",
      "stock_coverage": "Couverture de stock",
      "stockCoverageExplanation": "Calculé sur le stock fin de mois et la prévision finale",
      "zero_stock_days": "Jours de stock à zéro",
      "zeroStockExplanation": "Pourcentage moyen de jours sans stock sur les produits MTS",
      "supply_plan": "Plan d'approvisionnement",
      "supplyPlanExplanation": "Somme des commandes fermes et projetées, en valeur et en quantité"
    },
    "homeOutOfStock": {
      "avgDays": "En %",
      "noData": "Pas de données",
      "stockouts": "Jours de stock à zéro",
      "stockoutsTips": "Pourcentage moyen de jours sans stock."
    },
    "notFound": {
      "home": "la page d'accueil",
      "statement": "Oups, il semblerait que cette page n'existe pas.",
      "suggestion": "Souhaitez-vous aller à "
    }
  },
  "ru": {
    "dataJobHeader": {
      "dataWarningAlmostDone": "Данные обновляются... Сегодня это занимает немного больше времени, но через несколько минут все будет готово.",
      "dataWarningHour": "более часа.",
      "dataWarningMain": "Данные обновляются... Это займет ",
      "dataWarningMinutes": "{count} минуту. | {count} минуты. | {count} минут.",
      "dataWarningSubItem": "Вы можете продолжать работать, однако данные могут быть устаревшими.",
      "dataWarningThirtyMinutes": "более 30 минут."
    },
    "lineItem": {
      "aboveFlowlityMax": "Слишком много запасов",
      "allGood": "Все в порядке",
      "belowFlowlityMin": "Ниже рекомендованного минимума",
      "coverageDays": "Ваш текущий запас может покрыть {number} день(дней)",
      "day": "Д",
      "flowlitySupply": "Рекомендация от Flowlity",
      "fullyFrozen": "Период полностью заморожен",
      "lotSize": "Размер партии",
      "moq": "Минимальный объем заказа",
      "outOfStock": "Нет в наличии",
      "partlyFrozen": "Период частично заморожен, переключитесь на дни для редактирования этой поставки",
      "validatedTooltip": "Внимание, заказ на эту поставку был подтвержден"
    },
    "homeAccuracyChart": {
      "accuracy": "Точность прогноза",
      "accuracyTips": "Расчитан на основе прогноза Flowlity на различных горизонтах. Агрегированный MAPE сравнивает совокупный прогноз и совокупный фактический спрос. Взвешенный MAPE придает разный вес каждому продукту в зависимости от его фактического спроса",
      "aggregated": "Агрегированное",
      "average": "Среднее",
      "in": "в",
      "mapeExplanation": "MAPE определяет среднюю абсолютную разницу между прогнозом и фактическими данными. Чем ближе к нулю MAPE, тем лучше прогноз.",
      "month": "месяц | месяцев",
      "noData": "Нет данных",
      "weight": "Взвешенное",
      "whatMAPE": "Что такое MAPE?"
    },
    "homeSummary": {
      "allStock": "все запасы",
      "days": "дни",
      "daysOfCoverage": "Покрытие запасов",
      "noQuantityTips": "Для этого объема существует несколько единиц измерения по умолчанию. Аггрегация количеств невозможна.",
      "quantityLabel": "Количество",
      "trendsHistoryTips": "Уровень запасов отображается в конце месяца по количеству и стоимости; Покрытие запасов представляет собой количество дней запаса с учетом прогноза на конец месяца",
      "trendsHistoryValue": "Эволюция запасов (история и прогноз)",
      "valueLabel": "Стоимость",
      "valueLegend": "Стоимость запасов",
      "inPercentage": "в %",
      "inDays": "в днях",
      "demand_plan": "План спроса",
      "demandPlanExplanation": "Общий итоговый прогноз, доступный по стоимости и количеству",
      "inventory_level": "Уровень запасов",
      "inventoryLevelExplanation": "Уровень запасов отображается в конце месяца и доступен по стоимости и количеству",
      "stock_coverage": "Покрытие запасов",
      "stockCoverageExplanation": "Расчет на основе запасов на конец месяца и итогового прогноза",
      "zero_stock_days": "Дни с нулевыми запасами",
      "zeroStockExplanation": "Средний процент дней без запасов по продуктам MTS",
      "supply_plan": "План поставок",
      "supplyPlanExplanation": "Сумма обязательных и прогнозируемых заказов на поставки по стоимости и количеству"
    },
    "homeSummaryOrders": {
      "alreadyOrdered": "Отправить до",
      "byPriority": "по приоритету",
      "orderBefore": "Подтвердить до",
      "orders": "Заказ | Заказы",
      "ordersTips": "Список заказов, требующих немедленных действий.",
      "planned": "Запланированные",
      "confirmed": "Подтвержденные",
      "viewOrders": "Просмотреть все заказы"
    },
    "homeOutOfStock": {
      "avgDays": "В %",
      "noData": "Нет данных",
      "stockouts": "Дни нулевого запаса",
      "stockoutsTips": "Средний процент дней без запаса."
    },
    "notFound": {
      "home": "на главную",
      "statement": "Упс, извините, кажется, эта страница не существует.",
      "suggestion": "Может быть, вы хотите перейти "
    }
  }
}
