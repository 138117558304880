{
  "atNextDelivery": "при следующей доставке",
  "averageNext6M": "среднее за следующие 6 месяцев",
  "next1YAvg": "среднее за следующий год",
  "avgStockMinCoverageNext6M": "Минимальный запас (дни)",
  "avgStockMinQtyNext6M": "Минимальный запас (количество)",
  "mae": "Ошибка MAE прогноза",
  "maeExplanation": "Абсолютная ошибка между прогнозом Flowlity и прошлым спросом за последние {count} дней: ABS(спрос - прогноз) в количестве",
  "mape": "Ошибка MAPE прогноза",
  "mapeExplanation": "Средняя абсолютная процентная ошибка между прогнозом Flowlity и прошлым спросом за последние {count} дней: ABS(спрос - прогноз)/спрос (в %)",
  "forecastValueAdded": "Добавленная стоимость прогноза",
  "forecastValueAddedExplanation": "Прогноз Flowlity по сравнению со статистическим прогнозом (скользящее среднее за последние три месяца), если значение положительное, прогноз Flowlity лучше на X%",
  "bufferLevel": "Уровень буфера",
  "current": "текущий",
  "currentMonth": "текущий месяц",
  "Customers": "Клиенты",
  "daysShort": "д",
  "DefaultUnit": "Единица по умолчанию",
  "demand": "Спрос",
  "EndOfLife": "Конец жизненного цикла",
  "Events": "События",
  "FixingRecommendationsDate": "Фиксированные рекомендации",
  "FixingRecommendationsDateSubtitle": "До",
  "Promotions": "Акции",
  "FDemand": "F. Спрос",
  "FForecast": "F. Прогноз",
  "firmOrders": "Твердые заказы",
  "forecastSource": "Источник прогноза",
  "forecastSourceMyForecast": "Мой прогноз",
  "forecastSourceFlowlity": "Прогноз Flowlity",
  "forecastSourceMrp": "Внешний прогноз",
  "FinalForecastAgainstPastDemand": "тренд на следующие 30 дней",
  "FinalForecastNext30DaysCur": "следующие 30 дней, €",
  "FinalForecastNext30DaysQty": "следующие 30 дней, количество",
  "flowlityAi": "Flowlity AI",
  "myStockMin": "Мой минимальный запас",
  "FutureDemandNext30DaysCur": "следующие 30 дней, €",
  "FutureDemandNext30DaysQty": "следующие 30 дней, количество",
  "inventoryStrategy": "Стратегия инвентаризации",
  "InventoryValue": "Стоимость инвентаря",
  "initialValue": "Начальное значение",
  "last3M": "последние 3 месяца",
  "last6M": "последние 6 месяцев",
  "lastMonth": "прошлый месяц",
  "LeadTime": "Время выполнения",
  "mixed": "Смешанный",
  "nextDeliveryDate": "Дата следующей доставки",
  "none": "Нет",
  "PastDemandLast30DaysCur": "последние 30 дней, €",
  "PastDemandLast30DaysQty": "последние 30 дней, количество",
  "PastDemandLast30DaysTrend": "тренд за последние 30 дней",
  "PDemand": "P. Спрос",
  "ProductName": "Название продукта",
  "Reference": "Артикул",
  "reorderPoint": "Точка повторного заказа",
  "ropAI": "Точка повторного заказа + AI",
  "reviewDemand": "Обзор спроса",
  "reviewPlanning": "Обзор планирования",
  "ShelfLife": "Срок годности",
  "SimilarProducts": "Похожие продукты",
  "StockCoverage": "Покрытие запасов",
  "stockCoverageAtNextDeliveryTooltip": "Покрытие запасов в днях на следующую доставку (твердую или запланированную)",
  "stockCoverageCurrentMonthTooltip": "Покрытие запасов в днях - среднее за текущий месяц",
  "stockCoverageNext6MonthsTooltip": "Покрытие запасов в днях - среднее за следующие 6 месяцев",
  "stockCoverageNext1YAvgTooltip": "Покрытие запасов в днях - среднее за следующий год",
  "StockQty": "Количество запасов",
  "subContracted": "С компонентами",
  "Suppliers": "Поставщики",
  "SupplyBufferPolicy": "Поставка",
  "SupplyBufferPolicySubtitle": "Политика буфера",
  "TagName": "Название тега",
  "Tags": "Теги | Тег | Теги",
  "ZeroStockDays": "Дни нулевого запаса",
  "zeroStockDaysLast6MonthsTooltip": "Средний процент дней без запаса за последние 6 месяцев",
  "zeroStockDaysNext1YearTooltip": "Средний процент дней без запаса за следующий год",
  "zeroStockDaysLastMonthTooltip": "Средний процент дней без запаса за последний месяц",
  "forecastModelDemandType": "Тип спроса",
  "demandPredictability": "Предсказуемость",
  "demandPredictabilitySubtitle": "спроса",
  "demandPredictabilityTooltip": "Предсказуемость спроса - это качество данных о прошлом спросе, которые используются для создания прогноза",
  "demandPredictabilityNoTooltip": "Нет данных о прошлом спросе, невозможно создать прогноз",
  "demandPredictabilityLowTooltip": "Данные о прошлом спросе ограничены и не позволяют создать лучший прогноз",
  "demandPredictabilityMediumTooltip": "Flowlity предоставляет лучший прогноз, однако спрос демонстрировал нестабильное поведение",
  "demandPredictabilityHighTooltip": "Flowlity предоставляет лучший прогноз"
}
