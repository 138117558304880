{
  "en": {
    "alerts": {
      "aboveFlowlityMax": "Above Flowlity max",
      "alerts": "Alerts",
      "belowFlowlityMin": "Below Flowlity min",
      "endOfLife": "Possible end of life",
      "expiringStocks": "Expiring Stocks",
      "outlier": "Possible outlier",
      "shortage": "Possible shortage",
      "decreasingMrp": "Decreasing MRP",
      "aboveMyMax": "Above My stock max",
      "outOfStock": "Out of stock",
      "settings": "Alerts settings",
      "stockRelated": "Stock related",
      "eventRelated": "Event related",
      "save": "Save",
      "cancel": "Cancel"
    },
    "cancelFixRecoButton": "Cancel fixation",
    "selectAll": "Select All",
    "deselectAll": "Deselect All",
    "clearSelection": "Clear selection",
    "noResults": "No results",
    "keyword": "Reference",
    "columns": {
      "bufferLeadTime": "Time",
      "bufferStock": "Stock",
      "none": "None"
    },
    "demand_forecast": "Demand",
    "drawer": {
      "all": "All",
      "general": "General",
      "demand": "Demand",
      "inventory": "Inventory",
      "shown": "Shown in Table",
      "hidden": "Hidden in Table"
    },
    "deselectAllPage": "Deselect all on this page",
    "errorSavingNewFixingRecoDate": "An error occurred during the update of the date",
    "events": "Events",
    "promotions": "Promotions",
    "similarProducts": "Similar Products",
    "expiringStocks": "Expiring Stocks",
    "fixRecoButton": "FIX PERIOD",
    "legendDemandFrozenZone": "MTO zone",
    "legendDemandFrozenHorizon": "MTO horizon",
    "legendFixReco": "Fixed recommendations, Until",
    "tooltipHasForecastAtProductLevel": "My Forecast is not filled on some products",
    "tooltipHasForecastAtCustomerLevel": "My Forecast is not filled on some customers",
    "tooltipHasForecastAtProductCustomerLevel": "My Forecast is not filled on some products/customers",
    "dontHaveAccessToMyForecast": "You don't have access to modify My forecast",
    "wholeHorizonIsFrozen": "The whole horizon is fully frozen",
    "thisPeriodIsFrozen": "This period is frozen",
    "ropStrategyIsUsed": "ROP strategy is used",
    "onlyFirmDemandIsTaken": "Only firm demand is taken into account",
    "modal": {
      "searchAProduct": "Search a product",
      "searchACustomer": "Search a customer",
      "searchAProductOrACustomer": "Search a product or a customer",
      "aggregatedView": "Aggregation of",
      "bufferPolicyChangedWarning": "Inventory strategy changed",
      "tag": "tags",
      "product": "products",
      "bufferLeadTime": "Time buffer",
      "bufferStock": "Buffer Stock",
      "customTooltip": {
        "eol": "End of Life",
        "lateDeliveryWarning": "Delivery date from ERP is in past"
      },
      "day": "Days",
      "days": "days",
      "demand_forecast": "Demand Forecast",
      "demandToggle": "Demand",
      "editingModalTitle": "Editing",
      "editingProductsBelow": "Editing some products below",
      "month": "Months",
      "multipleItems": "Multiple Items",
      "dimensions": {
        "productView": "Product View",
        "tagView": "Tag View",
        "productAggregatedView": "Product Aggregated View",
        "customerView": "Customer View",
        "customers": "Customers",
        "products": "Products",
        "productAggregation": "Product aggregation"
      },
      "navigation": {
        "saveConfirmButton": "Yes",
        "discardCancelButton": "Back to Editing",
        "discardConfirmButton": "Discard changes",
        "discardText": "You will loose all changes that was made during editing",
        "discardTitle": "Discard changes ?",
        "saveTitle": "Apply changes and update the forecasts?"
      },
      "of": "of",
      "planning_forecast": "Planning Forecast",
      "productEvents": "Product Events",
      "switchUnitTo": "Switch to",
      "default": "Default",
      "secondary": "Secondary",
      "differentDefaultUnitsUsed": "Different default units are presented in this aggregation: {names}",
      "allDataComputedInDefaultUnits": "All data are shown in default units: Flowlity sums all values even if products have different names of default units",
      "viewBy": "view by",
      "week": "Weeks",
      "table": {
        "header": {
          "eol": "end of life"
        }
      },
      "planningSources": {
        "clientDemand": "My Forecast",
        "finalForecast": "Final forecast",
        "flowlityDemand": "Flowlity Forecast",
        "maximum": "Flowlity Max",
        "minimum": "Flowlity Min",
        "orders": "My Supply",
        "recommendation": "Flowlity Supply",
        "stock": "Stock",
        "stockCoverage": "Stock coverage",
        "stockCoveragetips": "Based on avg. daily demand",
        "tableSources": "Table Sources",
        "reorderPoint": "Reorder Point (ROP)",
        "myStockMin": "My Stock Min",
        "myStockMax": "My Stock Max"
      },
      "active": "Active",
      "basedOnMrp": "Based on {name}",
      "makeActive": "Make Active",
      "makeMrpActiveTitle": "Make {name} active source for forecast computation?",
      "makeFlowlityActiveTitle": "Make Flowlity Forecast active source for forecast computation?",
      "makeActiveBtn": "Make active",
      "supplyPlanBasedOnFlowlity": "Supply plan calculated based on the Flowlity forecast",
      "basedOnMrpExplanation": "First [X weeks] using {name} demand. You may see difference with more recent {name} data",
      "demandDrawer": {},
      "drawer": {
        "averageDelay": "Delay coverage",
        "constraints": "Constraints",
        "currentSite": "Current",
        "days": "days",
        "discardCancelButton": "Back to Editing",
        "discardConfirmButton": "Discard changes",
        "discardText": "You will loose all changes that was made during editing",
        "discardTitle": "Discard changes?",
        "edit": "Edit",
        "editTag": "Edit Tag",
        "fullTruckQty": "Full truck quantity",
        "general": "General",
        "lastStock": "Last known stock",
        "leadTime": "Lead time",
        "lotSize": "Lot size",
        "moq": "Minimum order quantity",
        "noStock": "No registered stock value found",
        "nextOrderDetails": "Next Order Details",
        "nextDeliveryDate": "Delivery Date",
        "nextDeliveryQty": "Quantity",
        "nextDeliverySupplier": "Supplier",
        "preferUnit": "Choose your prefer unit to display: ",
        "price": "Price/unit",
        "primaryUnit": "Primary Unit",
        "productClass": "Product Category",
        "productData": "Product Information",
        "productDetails": "Product Details",
        "productMoq": "Product MOQ",
        "productOptions": "Product options",
        "productSupplierLinkOrderFrequency": "Order frequency",
        "reference": "Reference",
        "saleStartAt": "Sales Start",
        "endOfLife": "End of Life",
        "secondaryUnit": "Secondary Unit",
        "secondaryUnitConv": "Secondary unit conversion",
        "showDetails": "Show details",
        "sitesOrderTitle": "Sites order",
        "stock": "Stock",
        "stockCoverage": "Coverage",
        "supplierMoq": "Supplier MOQ",
        "supplierOrderFrequency": "Product order frequency",
        "suppliersConstraints": "Suppliers Constraints",
        "tagInformation": "Tag Information",
        "tags": "Tags"
      },
      "DragToResize": "Drag to resize",
      "deleteChildrenMyForecast": {
        "buttonTooltipProducts": "Remove values from all products",
        "buttonTooltipCustomers": "Remove values from all customers",
        "confirmationModalTitle": "Remove My forecast values?",
        "confirmationModalDescriptionProducts": "It will remove My forecast values from all products that are part of current aggregation",
        "confirmationModalDescriptionCustomers": "It will remove My forecast values from all customers that are part of current aggregation",
        "confirmationModalConfirmBtnText": "Remove My forecast values",
        "confirmationModalCancelBtnText": "Cancel",
        "loadingModalTitle": "Product Aggregation is updating...",
        "loadingModalDescriptionProducts": "Please don't worry if this process is taking a while. We just need to update each product carefully",
        "loadingModalDescriptionCustomers": "Please don't worry if this process is taking a while. We just need to update each customer carefully",
        "successNotification": "My forecast values removed"
      },
      "similarProductTooltip": "Show Similar Products",
      "bomTooltip": "BOM products"
    },
    "invalidRefWarning": "This reference doesn’t exist in Flowlity",
    "name": "Name",
    "noResultsFound": "No results found",
    "internalCustomer": "Internal Customer",
    "noCustomers": "No Customers Found",
    "noTags": "No Tags Found",
    "nProductsFound": "0 products found | 1 product found | {count} products found",
    "planning_forecast": "Planning",
    "product": "Product",
    "productName": "Product Name",
    "products": "Products | Product | Products",
    "reference": "Reference",
    "refineSearch": "Try to refine your search & filters",
    "searchCustomer": "Customer",
    "searchProduct": "Product",
    "searchProductPlaceholder": "Search by product reference, product name or supplier",
    "searchTag": "Tag",
    "searchTagPlaceholder": "Search by tag name",
    "selectAllPage": "Select all on this page",
    "selectCustomer": "Select a customer",
    "selected": "{count} selected",
    "separated": "Separated",
    "combined": "Combined",
    "productsData": "Products Data",
    "reviewButton": {
      "tooltipDemand": "Update Demand Review Date",
      "tooltipPlanning": "Update Planning Review Date",
      "updating": "Updating...",
      "review": "Review"
    },
    "settings": {
      "alerts": "Alerts",
      "bufferLevel": "Buffer Level %",
      "bufferLevelTooltip": "Factor influencing the level of minimum stock: the higher the buffer level, the less risk you are ready to take on the product, and the higher the minimum stock",
      "customers": "Customers",
      "defaultUnitName": "Default Unit",
      "demandTrendTooltip": "Compares the last 30 days of demand [-1d;-30d] versus the previous 30 days of demand [-31d; -60d]",
      "endOfLife": "End of Life",
      "events": "Events",
      "promotions": "Promotions",
      "finalForecast": "Final Forecast",
      "next180DaysQty": "next 6 months, qty",
      "next360DaysQty": "next 12 months, qty",
      "last180DaysQty": "last 6 months, qty",
      "last360DaysQty": "last 12 months, qty",
      "finalForecastNext30DaysQtyTooltip": "Final forecast for the next 30 days in default unit",
      "finalForecastNext180DaysQtyTooltip": "Final forecast for the next 6 months in default unit",
      "finalForecastNext360DaysQtyTooltip": "Final forecast for the next 12 months in default unit",
      "finalForecastNext30DaysTrendTooltip": "Compares the past demand of the last 30 days and the final forecast of the next 30 days",
      "firmOrdersQtyTooltip": "Quantity of firm supply orders",
      "firmDemand": "Firm Demand",
      "firmOrders": "Firm Supply Orders",
      "firmDemandNext30DaysQtyTooltip": "Validated orders received from your external or internal customers (Transfer orders, production orders, etc.)",
      "flowlityForecast": "Flowlity Forecast",
      "forecastTrendTooltip": "Compares the next 30 days of final forecast versus the last 30 days of demand",
      "general": "General",
      "last30DaysCur": "last 30 days, €",
      "last30DaysQty": "last 30 days, qty",
      "last30DaysTrend": "last 30 days trend",
      "leadTime": "Lead Time",
      "next30DaysCur": "next 30 days, €",
      "next30DaysQty": "next 30 days, qty",
      "next30DaysTrend": "next 30 days trend",
      "pastDemand": "Past Demand",
      "pastDemandLast30DaysQtyTooltip": "Past demand for the last 30 days in default unit",
      "pastDemandLast180DaysQtyTooltip": "Past demand for the last 6 months in default unit",
      "pastDemandLast360DaysQtyTooltip": "Past demand for the last 12 months in default unit",
      "pastDemandLast30DaysTrendTooltip": "Compares the past demand between the periods [D-60; D-31] and [D-30; D-1]",
      "resetModalCancelButton": "Cancel",
      "resetModalConfirmButton": "Back to Default",
      "resetModalText": "You will lose your current active columns and order",
      "resetModalTitle": "Back to Default Columns Order?",
      "resetOrder": "Reset Order",
      "reviewDate": "Review Date",
      "reviewDemand": "Demand Review",
      "reviewPlanning": "Planning Review",
      "settingsTitle": "Columns",
      "shelfLife": "Shelf Life",
      "similarProducts": "Similar Products",
      "subContracted": "With Components",
      "suppliers": "Suppliers",
      "settingsUnavailable": "Сolumns customizing is not available in {view} view",
      "supplyBufferPolicy": "Supply Buffer Policy",
      "supplyBufferPolicyTooltip": "Strategy applied to cover for supplier uncertainty: stock or time buffer",
      "uncertainty": "Uncertainty"
    },
    "attributeSettings": {
      "noAttributesFound": "No attributes found",
      "continuous": "Continuous",
      "demandBasedConstant": "Flowlity AI + My stock min",
      "demandBasedFlowlity": "Flowlity AI",
      "demandType": "Demand type",
      "demandPredictability": "Demand predictability",
      "general": "General",
      "hasEol": "Has end of life",
      "hasPromotions": "Has future promotions",
      "hasDecreasingMrp": "Has decreasing MRP",
      "hasSimilarProducts": "Has similar products",
      "hasFixedPeriod": "Has fixed period",
      "inventoryStrategy": "Inventory policy",
      "leadTime": "Time buffer",
      "mto": "MTO",
      "mts": "MTS",
      "noEol": "No end of life",
      "noPolicy": "No buffer",
      "punctual": "Punctual",
      "reorderPoint": "Reorder point",
      "reorderPointAI": "Reorder point + AI",
      "stock": "Stock buffer",
      "supplyBufferPolicy": "Supply buffer policy",
      "withComponents": "With components",
      "punctualConstantFast": "Punctual constant fast",
      "punctualConstantSlow": "Punctual constant slow",
      "punctualLumpySlow": "Punctual lumpy slow",
      "punctualLumpyFast": "Punctual lumpy fast",
      "punctualSingleDemand": "Punctual single",
      "continuousLumpy": "Continuous lumpy",
      "continuousSmooth": "Continuous smooth",
      "zero": "No or poor data",
      "new": "Less 6 months data",
      "zeroTooltip": "Product with no past demand for more than 1 year",
      "newTooltip": "Products with less than six months of data",
      "punctualConstantFastTooltip": "Punctual product with a low demand volatile and short interval between two demands in average",
      "punctualConstantSlowTooltip": "Punctual product with a low demand volatile and long interval between two demands in average",
      "punctualLumpySlowTooltip": "Punctual product with a volatile demand and a long interval between two demands in average",
      "punctualLumpyFastTooltip": "Punctual product with a volatile demand and short interval between two demands in average",
      "punctualSingleDemandTooltip": "Punctual product with a unique point of demand in the past",
      "continuousLumpyTooltip": "Continuous product but demand is very volatile",
      "continuousSmoothTooltip": "Continuous product but demand is not very volatile",
      "noDemandPredictability": "No",
      "lowDemandPredictability": "Low",
      "mediumDemandPredictability": "Medium",
      "highDemandPredictability": "High",
      "forecastSource": "Forecast source",
      "externalForecast": "External forecast",
      "flowlityForecast": "Flowlity forecast",
      "myForecast": "My forecast"
    },
    "suppliersDropdownWithoutSuppliers": "Without supplier",
    "strategyOr": "Union",
    "strategyOrExplanation": "Product must be related to at least 1 of selected tags",
    "strategyAnd": "Intersection",
    "strategyAndExplanation": "Product must be related to all selected tags simultaneously",
    "successDeletingNewFixingRecoDate": "The date has been successfully deleted",
    "successSavingNewFixingRecoDate": "The new date has been successfully saved",
    "supplier": "Supplier",
    "suppliers": "Suppliers | Supplier | Suppliers",
    "suppliersDropdown": "Suppliers | Supplier: {name} | {count} Suppliers",
    "suppliersDropdownExcluded": "Suppliers | All except {name} | All except {count} Suppliers",
    "customer": "Customer",
    "customers": "Customers | Customer | Customers",
    "attributes": "Attributes | Attribute | Attributes",
    "tag": "Tag",
    "tagType": "Type",
    "tags": "Tags",
    "tagsCountable": "Tags  | Tag  | Tags",
    "tooltipNotDefaultTimebucket": "Switch to \"%{timebucket}\" time bucket to be able to edit My Forecast",
    "tooltipLegendDemandFrozenHorizon": "Only firm demand will be taken in the final forecast during this period",
    "tooltipLegendFixReco": "The recommendations are fixed during this period",
    "viewAggregatedDetails": "View Aggregated Details",
    "viewDetails": "View Details",
    "runPlanning": {
      "trigger": "Optimize",
      "loading": "Loading...",
      "explanation0": "setting up the optimization constraints...",
      "explanation1": "choosing the right planning strategy...",
      "explanation2": "doing math...",
      "explanation3": "almost done...",
      "runningTitle": "Running new planning",
      "failed": "failed",
      "canceled": "canceled",
      "done": "done",
      "disabledTooltip": "This action can't be executed while you edit Inventory strategy",
      "betaTitle": "The Optimize functionality does not manage the following constraints yet:",
      "betaSupplierMOQ": "Supplier MOQ",
      "betaFulTruck": "Full truck",
      "betaSOO": "Supplier order frequency",
      "betaMOQ": "MOQ & lot size per group of products",
      "betaForExample": "For example:",
      "betaForExampleText": "There is a supplier MOQ on products A and B, if Optimize product A, then the constraint applies only on Product A and orders of Product B are not taken into account."
    }
  },
  "fr": {
    "alerts": {
      "aboveFlowlityMax": "Au-dessus du max Flowlity",
      "alerts": "Alertes",
      "endOfLife": "Possible fin de vie",
      "outlier": "Possible anomalie de demande",
      "shortage": "Possible impact de rupture",
      "expiringStocks": "Stock expiré",
      "decreasingMrp": "Diminution de la demande MRP",
      "aboveMyMax": "Au-dessus de Mon stock max",
      "belowFlowlityMin": "En dessous du min Flowlity",
      "outOfStock": "Rupture de stock",
      "settings": "Paramètres des alertes",
      "stockRelated": "Stock",
      "eventRelated": "Événements",
      "save": "Enregistrer",
      "cancel": "Annuler"
    },
    "noCustomers": "Aucun Client Trouvé",
    "cancelFixRecoButton": "DÉFIGER",
    "selectAll": "Tout sélectionner",
    "deselectAll": "Tout désélectionner",
    "clearSelection": "Tout désélectionner",
    "customer": "Client",
    "customers": "Clients | Client | Clients",
    "attributes": "Attributs | Attribut | Attributs",
    "noResults": "Aucun resultat",
    "keyword": "Référence",
    "columns": {
      "bufferLeadTime": "Temps",
      "bufferStock": "Stock",
      "none": "Aucun"
    },
    "demand_forecast": " Demande",
    "drawer": {
      "all": "Tout",
      "general": "Général",
      "demand": "Demande",
      "inventory": "Stock",
      "shown": "Visible dans la table",
      "hidden": "Non-visible dans la table"
    },
    "deselectAllPage": "Désélectionner toute la page",
    "errorSavingNewFixingRecoDate": "Une erreur est survenue durant la mise à jour de la date",
    "events": "Événements",
    "promotions": "Promotions",
    "similarProducts": "Produits similaires",
    "expiringStocks": "Stock expiré",
    "fixRecoButton": "FIGER",
    "legendDemandFrozenZone": "Zone MTO",
    "legendDemandFrozenHorizon": "Horizon MTO",
    "legendFixReco": "Recommandations figées, jusqu'à",
    "modal": {
      "searchAProduct": "Chercher un produit",
      "searchACustomer": "Chercher un client",
      "searchAProductOrACustomer": "Chercher un produit ou un client",
      "aggregatedView": "Agrégation de",
      "bufferPolicyChangedWarning": "Changement de stratégie de stock",
      "tag": "tags",
      "product": "produits",
      "bufferLeadTime": "Time buffer",
      "bufferStock": "Stock buffer",
      "customTooltip": {
        "eol": "Fin de vie",
        "lateDeliveryWarning": "La date de livraison de l’ERP est dans le passé"
      },
      "table": {
        "header": {
          "eol": "fin de vie"
        }
      },
      "day": "Jours",
      "days": "jours",
      "demand_forecast": "Prévisions demande",
      "demandToggle": "Demande",
      "drawer": {
        "averageDelay": "Retard couvert",
        "constraints": "Contraintes",
        "currentSite": "Actuel",
        "days": "jours",
        "discardCancelButton": "Continuer d'éditer",
        "discardConfirmButton": "Annuler",
        "discardModalText": "Voulez-vous vraiment annuler vos modifications?",
        "discardModalTitle": "Annuler les modifications ?",
        "edit": "Modifier",
        "editTag": "Modifier le tag",
        "fullTruckQty": "Camion plein",
        "general": "Général",
        "lastStock": "Dernier niveau de stock",
        "leadTime": "Délai de livraison",
        "lotSize": "Taille de lot",
        "moq": "Qte min de commande",
        "noStock": "Aucune valeur de stock n'a été trouvée",
        "nextOrderDetails": "Détails de la prochaine commande",
        "nextDeliveryDate": "Date de livraison",
        "nextDeliveryQty": "Quantité",
        "nextDeliverySupplier": "Fournisseur",
        "preferUnit": "Choisissez votre unité préférée à afficher: ",
        "price": "Prix/unité",
        "primaryUnit": "Unité primaire",
        "productClass": "Catégorie Produit",
        "productData": "Information Produit",
        "productDetails": "Détail du produit",
        "productMoq": "Qte min de produit",
        "productOptions": "Options produit",
        "productSupplierLinkOrderFrequency": "Fréquence de commande",
        "reference": "Référence",
        "endOfLife": "Fin de vie",
        "saleStartAt": "Début de vente",
        "secondaryUnit": "Unité secondaire",
        "secondaryUnitConv": "Conversion en unité secondaire",
        "showDetails": "Voir le détail",
        "sitesOrderTitle": "Affichage des sites",
        "stock": "Stock",
        "stockCoverage": "Couverture",
        "supplierMoq": "Qté min du fournisseur",
        "supplierOrderFrequency": "Fréquence de commande du produit",
        "suppliersConstraints": "Contraintes fournisseurs",
        "tagInformation": "Détail du tag",
        "tags": "Tags"
      },
      "DragToResize": "Faites glisser pour redimensionner",
      "editingModalTitle": "Édition",
      "editingProductsBelow": "Édition de quelques produits ci-dessous",
      "month": "Mois",
      "multipleItems": "Produits Multiple",
      "dimensions": {
        "productView": "Vue produit",
        "tagView": "Vue tag",
        "productAggregatedView": "Vue agrégée",
        "customerView": "Vue client",
        "customers": "Clients",
        "products": "Produits",
        "productAggregation": "Agrégation produit"
      },
      "navigation": {
        "saveConfirmButton": "Oui",
        "discardCancelButton": "Continuer d'éditer",
        "discardConfirmButton": "Annuler les modifications",
        "discardText": "Vous perdrez tous les modifications qui ont été faits pendant l'édition",
        "discardTitle": "Annuler les modifications ?",
        "saveTitle": "Appliquer les modifications et mettre à jour les prédictions ?"
      },
      "of": "sur",
      "planning_forecast": "Prévisions planning",
      "planningSources": {
        "clientDemand": "Ma prédiction",
        "finalForecast": "Prédiction finale",
        "flowlityDemand": "Prédiction Flowlity",
        "maximum": "Flowlity Max",
        "minimum": "Flowlity Min",
        "orders": "Mon Plan",
        "recommendation": "Plan Flowlity",
        "stock": "Stock",
        "stockCoverage": "Couverture de stock",
        "stockCoveragetips": "Basé sur la demande journalière moyenne",
        "tableSources": "Sources",
        "reorderPoint": "Point de Commande (ROP)",
        "myStockMin": "Mon Stock Min",
        "myStockMax": "Mon Stock Max"
      },
      "active": "Actif",
      "basedOnMrp": "Basé sur {name}",
      "makeActive": "Activer",
      "makeMrpActiveTitle": "Activer les données {name} comme source pour le calcul des prévisions ?",
      "makeFlowlityActiveTitle": "Activer les prévisions Flowlity comme source pour le calcul des prévisions ?",
      "makeActiveBtn": "Activer",
      "supplyPlanBasedOnFlowlity": "Le plan d'approvisionnement est calculé en fonction de la prévision Flowlity",
      "basedOnMrpExplanation": "Première [X semaines] en utilisant la demande {name}. Vous pouvez constater une différence avec des données {name} plus récentes.",
      "productEvents": "Événements Produit",
      "switchUnitTo": "Changer d'unité:",
      "similarProductTooltip": "Afficher les produits similaires",
      "bomTooltip": "Afficher les produits liés par BOM",
      "default": "Défaut",
      "secondary": "Secondaire",
      "differentDefaultUnitsUsed": "Différentes unités par défaut sont présentées dans cette agrégation : {names}",
      "allDataComputedInDefaultUnits": "Toutes les données sont affichées dans les unités par défaut : Flowlity additionne toutes les valeurs même si les produits ont des noms d'unités par défaut différents",
      "viewBy": "vue par",
      "week": "Semaines",
      "deleteChildrenMyForecast": {
        "buttonTooltipProducts": "Supprime les valeurs de tous les produits",
        "buttonTooltipCustomers": "Supprime les valeurs de tous les clients",
        "confirmationModalTitle": "Supprimer les valeurs de Ma Prédiction ?",
        "confirmationModalDescriptionProducts": "Cela supprimera les valeurs de Ma Prédiction pour les produits liés à l'agrégation",
        "confirmationModalDescriptionCustomers": "Cela supprimera les valeurs de Ma Prédiction pour les clients liés à l'agrégation",
        "confirmationModalConfirmBtnText": "Supprimer Ma Prédiction",
        "confirmationModalCancelBtnText": "Annuler",
        "loadingModalTitle": "Mise à jour de l'agrégation par produit",
        "loadingModalDescriptionProducts": "Ne vous inquiétez pas si ce processus prend du temps. Nous avons juste besoin de mettre à jour soigneusement chaque produit.",
        "loadingModalDescriptionCustomers": "Ne vous inquiétez pas si ce processus prend du temps. Nous avons juste besoin de mettre à jour soigneusement chaque client.",
        "successNotification": "Les valeurs de Ma prédiction ont été supprimées"
      }
    },
    "invalidRefWarning": "Cette référence n'existe pas dans Flowlity",
    "name": "Nom",
    "noResultsFound": "Aucun résultat",
    "internalCustomer": "Client interne",
    "noTags": "Aucun Tag Trouvé",
    "nProductsFound": "0 produit trouvé | 1 produit trouvé | {count} produits trouvés",
    "planning_forecast": "Planning",
    "product": "Produit",
    "productName": "Désignation produit",
    "products": "produit | produit | produits",
    "reference": "Référence",
    "refineSearch": "Modifiez votre recherche et vos filtres",
    "searchProduct": "Produit",
    "searchProductPlaceholder": "Chercher par référence produit, nom de produit ou fournisseur",
    "searchTag": "Tag",
    "searchTagPlaceholder": "Chercher par nom de tag",
    "selectAllPage": "Sélectionner toute la page",
    "selectCustomer": "Sélectionner un client",
    "selected": "0 sélectionné | 1 sélectionné | {count} sélectionnés",
    "separated": "Separées",
    "combined": "Combinées",
    "productsData": "Données Produits",
    "searchCustomer": "Client",
    "reviewButton": {
      "tooltipDemand": "Mettre à jour la date de revue de la Demande",
      "tooltipPlanning": "Mettre à jour la date de revue du Planning",
      "updating": "Mise à jour...",
      "review": "Revue"
    },
    "settings": {
      "alerts": "Alertes",
      "bufferLevel": "Niv. de stock sécurité %",
      "bufferLevelTooltip": "Facteur qui influence le niveau de stock minimum : plus le niveau de stock sécurité est élevé, moins vous acceptez de risque sur le produit, et plus le niveau de stock minimum est élevé",
      "customers": "Clients",
      "defaultUnitName": "Unité par défaut",
      "demandTrendTooltip": "Comparaison entre les 30 derniers jours de demande [-1j;-30j] et les 30 jours d'avant [-31j; -60j]",
      "endOfLife": "Fin de vie",
      "events": "Événements",
      "promotions": "Promotions",
      "finalForecast": "Prédiction finale",
      "next180DaysQty": "6 prochains mois, qté",
      "next360DaysQty": "12 prochains mois, qté",
      "last180DaysQty": "6 derniers mois, qté",
      "last360DaysQty": "12 derniers mois, qté",
      "finalForecastNext30DaysQtyTooltip": "Prédiction finale des 30 prochains jours en unité par défaut",
      "finalForecastNext180DaysQtyTooltip": "Prédiction finale des 6 prochains mois en unité par défaut",
      "finalForecastNext360DaysQtyTooltip": "Prédiction finale des 12 prochains mois en unité par défaut",
      "finalForecastNext30DaysTrendTooltip": "Compare la demande passée des 30 derniers jours et la prédiction finale des 30 prochains jours",
      "firmOrdersQtyTooltip": "Quantité en commandes d'approvisionnement fermes",
      "firmDemand": "Demande ferme",
      "firmOrders": "Commandes d'appro. fermes",
      "firmDemandNext30DaysQtyTooltip": "Commandes validées reçues de vos clients externes ou internes (ordres de transfert, ordres de production, etc.)",
      "flowlityForecast": "Prédiction Flowlity",
      "forecastTrendTooltip": "Comparaison entre les 30 prochains jours de prévisions et les 30 derniers jours de demande",
      "general": "Général",
      "last30DaysCur": "passé 30 jours, €",
      "last30DaysQty": "passé 30 jours, qté",
      "last30DaysTrend": "passé 30 jours tendance",
      "leadTime": "Délai de livraison",
      "next30DaysCur": "futur 30 jours, €",
      "next30DaysQty": "futur 30 jours, qté",
      "next30DaysTrend": "futur 30 jours tendance",
      "pastDemand": "Demande passée",
      "pastDemandLast30DaysQtyTooltip": "Demande passée des 30 derniers jours en unité par défaut",
      "PastDemandLast180DaysQtyTooltip": "Demande passée des 6 derniers mois en unité par défaut",
      "PastDemandLast360DaysQtyTooltip": "Demande passée des 12 derniers mois en unité par défaut",
      "pastDemandLast30DaysTrendTooltip": "Compare la demande passée entre les périodes [J-60; J-31] et [J-30; J-1]",
      "resetModalCancelButton": "Annuler",
      "resetModalConfirmButton": "Réinitialiser",
      "resetModalText": "Vous perdrez les paramètres actuels concernant l'ordre et l'activation des colonnes",
      "resetModalTitle": "Réinitialiser l'affichage par défaut ?",
      "resetOrder": "Réinitialiser l'ordre",
      "reviewDate": "Date de revue",
      "reviewDemand": "Revue de la Demande",
      "reviewPlanning": "Revue du Planning",
      "settingsTitle": "Colonnes",
      "shelfLife": "Durée de vie",
      "similarProducts": "Produits similaires",
      "subContracted": "Avec Composants",
      "suppliers": "Fournisseurs",
      "settingsUnavailable": "La personnalisation des colonnes n'est pas disponible dans la vue {view}",
      "supplyBufferPolicy": "Politique de buffer (Appros)",
      "supplyBufferPolicyTooltip": "Stratégie appliquée pour couvrir l'incertitude fournisseur : buffer en temps ou en stock",
      "uncertainty": "Incertitude"
    },
    "attributeSettings": {
      "noAttributesFound": "Aucun attribut trouvé",
      "continuous": "Continue",
      "demandBasedConstant": "Flowlity IA + Mon stock min",
      "demandBasedFlowlity": "Flowlity IA",
      "demandType": "Type de demande",
      "demandPredictability": "Prédictibilité de la demande",
      "general": "General",
      "hasEol": "Avec fin de vie",
      "hasPromotions": "Avec des promotions futures",
      "hasDecreasingMrp": "Avec diminution de la demande MRP",
      "hasSimilarProducts": "Avec produits similaires",
      "hasFixedPeriod": "Avec période figée",
      "inventoryStrategy": "Stratégie de stock",
      "leadTime": "Buffer temps",
      "mto": "MTO",
      "mts": "MTS",
      "noEol": "Pas de fin de vie",
      "noPolicy": "Pas de buffer",
      "punctual": "Ponctuelle",
      "reorderPoint": "Point de commande",
      "reorderPointAI": "Point de commande + IA",
      "stock": "Buffer Stock",
      "supplyBufferPolicy": "Politique de buffer (Appros)",
      "withComponents": "Avec composants",
      "punctualConstantFast": "Ponctuelle constante rapide",
      "punctualConstantSlow": "Ponctuelle constante lente",
      "punctualLumpySlow": "Ponctuelle irrégulière lente",
      "punctualLumpyFast": "Ponctuelle irrégulière rapide",
      "punctualSingleDemand": "Ponctuelle unique",
      "continuousLumpy": "Continue irrégulière",
      "continuousSmooth": "Continue régulière",
      "zero": "Pas ou peu de données",
      "new": "Moins de 6 mois de données",
      "zeroTooltip": "Produit sans demande passée depuis plus d'un an",
      "newTooltip": "Produits avec moins de six mois de données",
      "punctualConstantFastTooltip": "Produit ponctuel avec une demande faible, volatile et un intervalle court entre deux demandes en moyenne",
      "punctualConstantSlowTooltip": "Produit ponctuel avec une demande faible, volatile et un intervalle long entre deux demandes en moyenne",
      "punctualLumpySlowTooltip": "Produit ponctuel avec une demande volatile et un intervalle long entre deux demandes en moyenne",
      "punctualLumpyFastTooltip": "Produit ponctuel avec une demande volatile et un intervalle court entre deux demandes en moyenne",
      "punctualSingleDemandTooltip": "Produit ponctuel avec un unique point de demande dans le passé",
      "continuousLumpyTooltip": "Produit continu mais la demande est très volatile",
      "continuousSmoothTooltip": "Produit continu mais la demande n'est pas très volatile",
      "noDemandPredictability": "Non",
      "lowDemandPredictability": "Faible",
      "mediumDemandPredictability": "Moyenne",
      "highDemandPredictability": "Élevée",
      "forecastSource": "Source de prédiction",
      "externalForecast": "Prédiction externe",
      "flowlityForecast": "Prédiction flowlity",
      "myForecast": "Ma Prédiction"
    },
    "suppliersDropdownWithoutSuppliers": "Sans fournisseur",
    "strategyOr": "Union",
    "strategyOrExplanation": "Un produit doit être lié à au moins 1 des tags sélectionnés",
    "strategyAnd": "Intersection",
    "strategyAndExplanation": "Un produit doit être lié à tous les tags sélectionnés simultanément",
    "successDeletingNewFixingRecoDate": "La date a bien été supprimée",
    "successSavingNewFixingRecoDate": "La nouvelle date a bien été enregistrée",
    "supplier": "Fournisseur",
    "suppliers": "Fournisseur | Fournisseur | Fournisseurs",
    "suppliersDropdown": "Fournisseurs | Fournisseur: {name} | {count} Fournisseurs",
    "suppliersDropdownExcluded": "Fournisseurs | Tous sauf {name} | Tous sauf {count} Fournisseurs",
    "tag": "Tag",
    "tagType": "Type",
    "tags": "Tags",
    "tagsCountable": "tags  | tag  | tags",
    "tooltipNotDefaultTimebucket": "Changer la maille temporelle en \"%{timebucket}\" pour pouvoir modifier...",
    "tooltipHasForecastAtProductLevel": "Ma Prévision n’est pas renseignée sur certains produits",
    "tooltipHasForecastAtCustomerLevel": "Ma Prévision n’est pas renseignée sur certains clients",
    "tooltipHasForecastAtProductCustomerLevel": "Ma Prévision n’est pas renseignée sur certains paires produit/client",
    "dontHaveAccessToMyForecast": "Vous n'avez pas accès pour modifier Ma prévision",
    "wholeHorizonIsFrozen": "Tout l'horizon est complètement figé",
    "thisPeriodIsFrozen": "Cette période est figée",
    "ropStrategyIsUsed": "La stratégie ROP est utilisée",
    "onlyFirmDemandIsTaken": "Seule la demande ferme est prise en compte",
    "tooltipLegendDemandFrozenHorizon": "Seule la demande ferme sera prise en compte pour la prédiction finale durant cette période",
    "tooltipLegendFixReco": "Les recommandations sont figées durant cette période",
    "viewAggregatedDetails": "Voir le détail agrégé",
    "viewDetails": "Voir le détail",
    "runPlanning": {
      "trigger": "Optimiser",
      "loading": "Chargement...",
      "explanation0": "mise en place des contraintes d'optimisation...",
      "explanation1": "choix de la bonne stratégie de planning...",
      "explanation2": "calculs en cours...",
      "explanation3": "presque terminé...",
      "runningTitle": "Calcul du nouveau planning",
      "failed": "échoué",
      "canceled": "annulé",
      "done": "terminé",
      "disabledTooltip": "Cette action ne peut être réalisée en même temps que la modification de la stratégie de stock",
      "betaTitle": "Pour le moment, la fonctionnalité Optimiser ne prend pas en compte les contraintes suivantes:",
      "betaSupplierMOQ": "MOQ fournisseur",
      "betaFulTruck": "Camion plein",
      "betaSOO": "Fréquence de commande fournisseur",
      "betaMOQ": "MOQ & taille de lot par groupe de produits",
      "betaForExample": "Par example:",
      "betaForExampleText": "Il y a un MOQ fournisseur sur les produits A et B, si on Optimise le produit A alors la contrainte s'applique uniquement au produit A et les commandes du produit B ne sont pas prises en compte."
    }
  },
  "ru": {
    "alerts": {
      "aboveFlowlityMax": "Выше максимума Flowlity",
      "alerts": "Предупреждения",
      "belowFlowlityMin": "Ниже минимума Flowlity",
      "endOfLife": "Возможный конец жизненного цикла",
      "expiringStocks": "Истекающие запасы",
      "outlier": "Возможная аномалия",
      "shortage": "Возможная нехватка",
      "decreasingMrp": "Снижение MRP",
      "aboveMyMax": "Выше моего максимального запаса",
      "outOfStock": "Нет в наличии",
      "settings": "Настройки оповещений",
      "stockRelated": "Связано с запасами",
      "eventRelated": "Связано с событиями",
      "save": "Сохранить",
      "cancel": "Отмена"
    },
    "cancelFixRecoButton": "Отменить фиксацию",
    "selectAll": "Выбрать все",
    "deselectAll": "Снять все выделения",
    "clearSelection": "Очистить выбор",
    "noResults": "Нет результатов",
    "keyword": "Артикул",
    "columns": {
      "bufferLeadTime": "Время",
      "bufferStock": "Запас",
      "none": "Нет"
    },
    "demand_forecast": "Спрос",
    "drawer": {
      "all": "Все",
      "general": "Общее",
      "demand": "Спрос",
      "inventory": "Инвентаризация",
      "shown": "Показано в таблице",
      "hidden": "Скрыто в таблице"
    },
    "deselectAllPage": "Снять все выделения на этой странице",
    "errorSavingNewFixingRecoDate": "Произошла ошибка при обновлении даты",
    "events": "События",
    "promotions": "Промоакции",
    "similarProducts": "Похожие продукты",
    "expiringStocks": "Истекающие запасы",
    "fixRecoButton": "ЗАФИКСИРОВАТЬ ПЕРИОД",
    "legendDemandFrozenZone": "Зона MTO",
    "legendDemandFrozenHorizon": "Горизонт MTO",
    "legendFixReco": "Зафиксированные рекомендации, до",
    "tooltipHasForecastAtProductLevel": "Мой прогноз не заполнен для некоторых продуктов",
    "tooltipHasForecastAtCustomerLevel": "Мой прогноз не заполнен для некоторых клиентов",
    "tooltipHasForecastAtProductCustomerLevel": "Мой прогноз не заполнен для некоторых продуктов/клиентов",
    "dontHaveAccessToMyForecast": "У вас нет доступа для изменения Моего прогноза",
    "wholeHorizonIsFrozen": "Весь горизонт полностью заморожен",
    "thisPeriodIsFrozen": "Этот период заморожен",
    "ropStrategyIsUsed": "Используется стратегия ROP",
    "onlyFirmDemandIsTaken": "Учитывается только твердый спрос",
    "modal": {
      "searchAProduct": "Поиск продукта",
      "searchACustomer": "Поиск клиента",
      "searchAProductOrACustomer": "Поиск продукта или клиента",
      "aggregatedView": "Агрегация",
      "bufferPolicyChangedWarning": "Стратегия инвентаризации изменена",
      "tag": "теги",
      "product": "продукты",
      "bufferLeadTime": "Буфер времени",
      "bufferStock": "Буферный запас",
      "customTooltip": {
        "eol": "Конец жизненного цикла",
        "lateDeliveryWarning": "Дата доставки из ERP в прошлом"
      },
      "day": "Дни",
      "days": "дней",
      "demand_forecast": "Прогноз спроса",
      "demandToggle": "Спрос",
      "editingModalTitle": "Редактирование",
      "editingProductsBelow": "Редактирование некоторых продуктов ниже",
      "month": "Месяцы",
      "multipleItems": "Несколько элементов",
      "dimensions": {
        "productView": "Вид продукта",
        "tagView": "Вид по тегу",
        "productAggregatedView": "Агрегированный вид продукта",
        "customerView": "Вид клиента",
        "customers": "Клиенты",
        "products": "Продукты",
        "productAggregation": "Агрегация продуктов"
      },
      "navigation": {
        "saveConfirmButton": "Да",
        "discardCancelButton": "Вернуться к редактированию",
        "discardConfirmButton": "Отменить изменения",
        "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
        "discardTitle": "Отменить изменения?",
        "saveTitle": "Применить изменения и обновить прогнозы?"
      },
      "of": "из",
      "planning_forecast": "Планирование прогноза",
      "productEvents": "События продукта",
      "switchUnitTo": "Переключиться на",
      "default": "По умолчанию",
      "secondary": "Вторичный",
      "viewBy": "вид по",
      "week": "Недели",
      "table": {
        "header": {
          "eol": "Конец жизненного цикла"
        }
      },
      "planningSources": {
        "clientDemand": "Мой прогноз",
        "finalForecast": "Окончательный прогноз",
        "flowlityDemand": "Прогноз Flowlity",
        "maximum": "Максимум Flowlity",
        "minimum": "Минимум Flowlity",
        "orders": "Мои поставки",
        "recommendation": "Поставка Flowlity",
        "stock": "Запас",
        "stockCoverage": "Покрытие запасов",
        "stockCoveragetips": "Основано на среднем ежедневном спросе",
        "tableSources": "Источники таблицы",
        "reorderPoint": "Точка повторного заказа (ROP)",
        "myStockMin": "Мой минимальный запас",
        "myStockMax": "Мой максимальный запас"
      },
      "active": "Активный",
      "basedOnMrp": "Основано на {name}",
      "makeActive": "Сделать активным",
      "makeMrpActiveTitle": "Сделать {name} активным источником для расчета прогноза?",
      "makeFlowlityActiveTitle": "Сделать прогноз Flowlity активным источником для расчета прогноза?",
      "makeActiveBtn": "Сделать активным",
      "supplyPlanBasedOnFlowlity": "План поставок, рассчитанный на основе прогноза Flowlity",
      "basedOnMrpExplanation": "Первый [X недель] используя спрос {name}. Вы можете увидеть разницу с более свежими данными {name}",
      "demandDrawer": {},
      "drawer": {
        "averageDelay": "Покрытие задержек",
        "constraints": "Ограничения",
        "days": "дней",
        "discardCancelButton": "Вернуться к редактированию",
        "discardConfirmButton": "Отменить изменения",
        "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
        "discardTitle": "Отменить изменения?",
        "edit": "Редактировать",
        "editTag": "Редактировать тег",
        "fullTruckQty": "Количество для полного грузовика",
        "general": "Общее",
        "lastStock": "Последний известный запас",
        "leadTime": "Время выполнения",
        "lotSize": "Размер партии",
        "moq": "Минимальный объем заказа",
        "noStock": "Не найдено зарегистрированного значения запаса",
        "preferUnit": "Выберите предпочитаемую единицу для отображения: ",
        "price": "Цена/единица",
        "primaryUnit": "Основная единица",
        "productClass": "Категория продукта",
        "productData": "Информация о продукте",
        "productDetails": "Детали продукта",
        "productMoq": "MOQ продукта",
        "productOptions": "Параметры продукта",
        "productSupplierLinkOrderFrequency": "Частота заказов",
        "reference": "Артикул",
        "saleStartAt": "Начало продаж",
        "endOfLife": "Конец жизненного цикла",
        "secondaryUnit": "Вторичная единица",
        "secondaryUnitConv": "Преобразование вторичной единицы",
        "showDetails": "Показать детали",
        "supplierMoq": "MOQ поставщика",
        "supplierOrderFrequency": "Частота заказов продукта",
        "suppliersConstraints": "Ограничения поставщиков",
        "tagInformation": "Информация о теге",
        "tags": "Теги"
      },
      "DragToResize": "Перетащите для изменения размера",
      "deleteChildrenMyForecast": {
        "buttonTooltipProducts": "Удалить значения из всех продуктов",
        "buttonTooltipCustomers": "Удалить значения из всех клиентов",
        "confirmationModalTitle": "Удалить значения Моего прогноза?",
        "confirmationModalDescriptionProducts": "Это удалит значения Моего прогноза из всех продуктов, которые входят в текущую агрегацию",
        "confirmationModalDescriptionCustomers": "Это удалит значения Моего прогноза из всех клиентов, которые входят в текущую агрегацию",
        "confirmationModalConfirmBtnText": "Удалить значения Моего прогноза",
        "confirmationModalCancelBtnText": "Отмена",
        "loadingModalTitle": "Агрегация продуктов обновляется...",
        "loadingModalDescriptionProducts": "Не волнуйтесь, если этот процесс займет некоторое время. Нам просто нужно тщательно обновить каждый продукт",
        "loadingModalDescriptionCustomers": "Не волнуйтесь, если этот процесс займет некоторое время. Нам просто нужно тщательно обновить каждого клиента",
        "successNotification": "Значения Моего прогноза удалены"
      },
      "similarProductTooltip": "Показать похожие продукты",
      "bomTooltip": "Показать продукты, связанные по BOM"
    },
    "invalidRefWarning": "Этот артикул не существует в Flowlity",
    "name": "Имя",
    "noResultsFound": "Результатов не найдено",
    "internalCustomer": "Внутренний клиент",
    "noCustomers": "Клиенты не найдены",
    "noTags": "Теги не найдены",
    "nProductsFound": "Найдено 0 продуктов | Найден 1 продукт | Найдено {count} продуктов",
    "planning_forecast": "Планирование",
    "product": "Продукт",
    "productName": "Название продукта",
    "products": "Продукты | Продукт | Продукты",
    "reference": "Артикул",
    "refineSearch": "Попробуйте уточнить ваш поиск и фильтры",
    "searchCustomer": "Клиент",
    "searchProduct": "Продукт",
    "searchProductPlaceholder": "Поиск по ссылке продукта, названию продукта или поставщику",
    "searchTag": "Тег",
    "searchTagPlaceholder": "Поиск по названию тега",
    "selectAllPage": "Выбрать все на этой странице",
    "selectCustomer": "Выберите клиента",
    "selected": "{count} выбрано",
    "separated": "Разделено",
    "combined": "Объединено",
    "productsData": "Данные продуктов",
    "reviewButton": {
      "tooltipDemand": "Обновить дату обзора спроса",
      "tooltipPlanning": "Обновить дату обзора планирования",
      "updating": "Обновление...",
      "review": "Обзор"
    },
    "settings": {
      "alerts": "Предупреждения",
      "bufferLevel": "Уровень буфера %",
      "bufferLevelTooltip": "Фактор, влияющий на уровень минимального запаса: чем выше уровень буфера, тем меньше риска вы готовы принять по продукту, и тем выше минимальный запас",
      "customers": "Клиенты",
      "defaultUnitName": "Единица по умолчанию",
      "demandTrendTooltip": "Сравнивает последние 30 дней спроса [-1d;-30d] с предыдущими 30 днями спроса [-31d; -60d]",
      "endOfLife": "Конец жизненного цикла",
      "events": "События",
      "promotions": "Промоакции",
      "finalForecast": "Окончательный прогноз",
      "finalForecastNext30DaysQtyTooltip": "Окончательный прогноз на следующие 30 дней в единице по умолчанию",
      "finalForecastNext30DaysTrendTooltip": "Сравнивает прошлый спрос за последние 30 дней и окончательный прогноз на следующие 30 дней",
      "firmOrdersQtyTooltip": "Количество твердых заказов на поставку",
      "firmDemand": "Твердый спрос",
      "firmOrders": "Твердые заказы",
      "firmDemandNext30DaysQtyTooltip": "Подтвержденные заказы от ваших внешних или внутренних клиентов (заказы на перевод, производственные заказы и т.д.)",
      "flowlityForecast": "Прогноз Flowlity",
      "forecastTrendTooltip": "Сравнивает следующие 30 дней окончательного прогноза с последними 30 днями спроса",
      "general": "Общее",
      "last30DaysCur": "последние 30 дней, €",
      "last30DaysQty": "последние 30 дней, количество",
      "last30DaysTrend": "тренд за последние 30 дней",
      "leadTime": "Время выполнения",
      "next30DaysCur": "следующие 30 дней, €",
      "next30DaysQty": "следующие 30 дней, количество",
      "next30DaysTrend": "тренд на следующие 30 дней",
      "pastDemand": "Прошлый спрос",
      "pastDemandLast30DaysQtyTooltip": "Прошлый спрос за последние 30 дней в единице по умолчанию",
      "pastDemandLast30DaysTrendTooltip": "Сравнивает прошлый спрос между периодами [D-60; D-31] и [D-30; D-1]",
      "resetModalCancelButton": "Отмена",
      "resetModalConfirmButton": "Вернуться к настройкам по умолчанию",
      "resetModalText": "Вы потеряете текущие активные столбцы и порядок",
      "resetModalTitle": "Вернуться к настройкам столбцов по умолчанию?",
      "resetOrder": "Сбросить порядок",
      "reviewDate": "Дата обзора",
      "reviewDemand": "Обзор спроса",
      "reviewPlanning": "Обзор планирования",
      "settingsTitle": "Столбцы",
      "shelfLife": "Срок годности",
      "similarProducts": "Похожие продукты",
      "subContracted": "С компонентами",
      "suppliers": "Поставщики",
      "settingsUnavailable": "Настройка столбцов недоступна в представлении {view}",
      "supplyBufferPolicy": "Политика буфера поставок",
      "supplyBufferPolicyTooltip": "Стратегия, применяемая для покрытия неопределенности поставщика: буфер запасов или времени",
      "uncertainty": "Неопределенность"
    },
    "attributeSettings": {
      "noAttributesFound": "Атрибуты не найдены",
      "continuous": "Непрерывный",
      "demandBasedConstant": "Flowlity AI + Мой минимальный запас",
      "demandBasedFlowlity": "Flowlity AI",
      "demandType": "Тип спроса",
      "demandPredictability": "Предсказуемость спроса",
      "general": "Общее",
      "hasEol": "Имеет конец жизненного цикла",
      "hasPromotions": "Имеет промоакции",
      "hasDecreasingMrp": "Имеет снижение MRP",
      "hasSimilarProducts": "Имеет похожие продукты",
      "hasFixedPeriod": "Имеет зафиксированный период",
      "inventoryStrategy": "Политика инвентаризации",
      "leadTime": "Буфер времени",
      "mto": "MTO",
      "mts": "MTS",
      "noEol": "Нет конца жизни",
      "noPolicy": "Без буфера",
      "punctual": "Пунктуальный",
      "reorderPoint": "Точка повторного заказа",
      "reorderPointAI": "Точка повторного заказа + AI",
      "stock": "Буфер запасов",
      "supplyBufferPolicy": "Политика буфера поставок",
      "withComponents": "С компонентами",
      "punctualConstantFast": "Пунктуальный постоянный быстрый",
      "punctualConstantSlow": "Пунктуальный постоянный медленный",
      "punctualLumpySlow": "Пунктуальный переменный медленный",
      "punctualLumpyFast": "Пунктуальный переменный быстрый",
      "punctualSingleDemand": "Пунктуальный единичный спрос",
      "continuousLumpy": "Непрерывный переменный",
      "continuousSmooth": "Непрерывный гладкий",
      "zero": "Нет или мало данных",
      "new": "Менее 6 месяцев данных",
      "zeroTooltip": "Продукт без прошлого спроса более 1 года",
      "newTooltip": "Продукты с данными менее шести месяцев",
      "punctualConstantFastTooltip": "Пунктуальный продукт с низкой волатильностью спроса и коротким интервалом между двумя спросами в среднем",
      "punctualConstantSlowTooltip": "Пунктуальный продукт с низкой волатильностью спроса и длинным интервалом между двумя спросами в среднем",
      "punctualLumpySlowTooltip": "Пунктуальный продукт с волатильным спросом и длинным интервалом между двумя спросами в среднем",
      "punctualLumpyFastTooltip": "Пунктуальный продукт с волатильным спросом и коротким интервалом между двумя спросами в среднем",
      "punctualSingleDemandTooltip": "Пунктуальный продукт с единственной точкой спроса в прошлом",
      "continuousLumpyTooltip": "Непрерывный продукт, но спрос очень волатильный",
      "continuousSmoothTooltip": "Непрерывный продукт, но спрос не очень волатильный",
      "noDemandPredictability": "Нет",
      "lowDemandPredictability": "Низкая",
      "mediumDemandPredictability": "Средняя",
      "highDemandPredictability": "Высокая",
      "forecastSource": "Источник прогноза",
      "externalForecast": "Внешний прогноз",
      "flowlityForecast": "Прогноз Flowlity",
      "myForecast": "Мой прогноз"
    },
    "suppliersDropdownWithoutSuppliers": "Без поставщика",
    "strategyOr": "Объединение",
    "strategyOrExplanation": "Продукт должен быть привязан хотя бы к одному из выбранных тегов",
    "strategyAnd": "Пересечение",
    "strategyAndExplanation": "Продукт должен быть привязан ко всем выбранным тегам одновременно",
    "successDeletingNewFixingRecoDate": "Дата успешно удалена",
    "successSavingNewFixingRecoDate": "Новая дата успешно сохранена",
    "supplier": "Поставщик",
    "suppliers": "Поставщики | Поставщик | Поставщики",
    "suppliersDropdown": "Поставщики | Поставщик: {name} | {count} Поставщики",
    "suppliersDropdownExcluded": "Поставщики | Все, кроме {name} | Все, кроме {count} Поставщики",
    "customer": "Клиент",
    "customers": "Клиенты | Клиент | Клиенты",
    "attributes": "Атрибуты | Атрибут | Атрибуты",
    "tag": "Тег",
    "tagType": "Тип",
    "tags": "Теги",
    "tagsCountable": "Теги  | Тег  | Теги",
    "tooltipNotDefaultTimebucket": "Переключитесь на \"%{timebucket}\" для редактирования Моего прогноза",
    "tooltipLegendDemandFrozenHorizon": "Только твердый спрос будет учитываться в окончательном прогнозе в этот период",
    "tooltipLegendFixReco": "Рекомендации зафиксированы в этот период",
    "viewAggregatedDetails": "Просмотр агрегированных данных",
    "viewDetails": "Просмотр данных",
    "runPlanning": {
      "trigger": "Оптимизировать",
      "loading": "Загрузка...",
      "explanation0": "настройка ограничений оптимизации...",
      "explanation1": "выбор правильной стратегии планирования...",
      "explanation2": "математические вычисления...",
      "explanation3": "почти готово...",
      "runningTitle": "Запуск нового планирования",
      "failed": "неудачно",
      "canceled": "отменено",
      "done": "готово",
      "disabledTooltip": "Это действие невозможно выполнить, пока вы редактируете стратегию инвентаризации",
      "betaTitle": "Функция Оптимизации пока не управляет следующими ограничениями:",
      "betaSupplierMOQ": "MOQ поставщика",
      "betaFulTruck": "Полный грузовик",
      "betaSOO": "Частота заказов поставщика",
      "betaMOQ": "MOQ и размер партии для группы продуктов",
      "betaForExample": "Например:",
      "betaForExampleText": "Существует MOQ поставщика на продукты A и B, если оптимизировать продукт A, то ограничение применяется только к продукту A, а заказы на продукт B не учитываются."
    }
  }
}
