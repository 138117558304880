{
  "requiredError": "Это поле обязательно",
  "requiredNumberError": "Пожалуйста, введите допустимое число",
  "requiredStringError": "Пожалуйста, введите допустимую строку",
  "main": {
    "exportTitle": "Загрузить данные",
    "settings": "Настройки",
    "company": "Компания",
    "sites": "Склады",
    "users": "Пользователи",
    "suppliers": "Поставщики",
    "customers": "Клиенты",
    "products": "Продукты",
    "promotions": "Акции",
    "bufferPolicy": "Политика буферизации",
    "maintenances": "Техническое обслуживание",
    "tags": "Теги",
    "general": "Общие",
    "profile": "Профиль",
    "parameters": "Параметры"
  },
  "company": {
    "title": "Компания",
    "name": "Имя",
    "nameError": "Пожалуйста, добавьте название компании",
    "cancel": "Отмена"
  },
  "bufferPolicy": {
    "orderPointValue": "Значение точки заказа",
    "constantMinimumTip": "Мы будем использовать это как фиксированное значение для всего горизонта",
    "lowerLimitMin": "Нижний предел мин",
    "useLowerLimitMin": "Добавить нижний предел мин",
    "title": "Политика буферизации",
    "MTSFlowlityAITitle": "Политика буфера поставок по умолчанию для стратегии MTS Flowlity AI",
    "MTSMyMinTitle": "Политика буфера поставок по умолчанию для стратегии MTS My min",
    "MTSROPTitle": "Политика буфера поставок по умолчанию для стратегии MTS точки повторного заказа",
    "MTSROPAITitle": "Политика буфера поставок по умолчанию для стратегии MTS точки повторного заказа + AI",
    "MTOtitle": "Политика буфера поставок по умолчанию для стратегии MTO",
    "subtitle": "Она будет применяться ко всем продуктам с этими стратегиями, где не настроена пользовательская политика.",
    "none": "Нет",
    "time": "Время",
    "stock": "Запас",
    "supplierDelay": "Задержка поставщика",
    "inputDisabledTooltip": "Нет задержки поставщика, так как политика не применяется",
    "errorEmpty": "Задержка поставщика не может быть пустой, она должна быть 1 день или более",
    "errorZero": "Задержка поставщика не может быть 0, она должна быть 1 день или более",
    "errorNegative": "Задержка поставщика не может быть отрицательной, она должна быть 1 день или более",
    "save": "Сохранить",
    "discard": "Отменить изменения",
    "updateSuccess": "Политика буферизации обновлена",
    "updateError": "Произошла ошибка при обновлении политики буферизации",
    "saveTitle": "Сохранить изменения?",
    "saveDescription": "Изменения будут применены к продуктам, использующим политику буферизации по умолчанию",
    "saveConfirmButton": "Сохранить изменения",
    "cancel": "Отмена",
    "days": "дни",
    "minFixedValue": "Мой минимальный уровень запасов"
  },
  "export": {
    "title": "Загрузки",
    "desc": "Пожалуйста, выберите файл и формат для загрузки",
    "salesOrders": "Заказы на продажу",
    "purchaseOrders": "Заказы на покупку",
    "planning": "Планирование",
    "demands": "Прогнозы спроса",
    "file": "Файл(ы)",
    "localDownload": "Локальная загрузка",
    "sftpSuccess": "Данные успешно экспортированы",
    "exportError": "Экспорт не удался с ошибкой: ",
    "period": "Период",
    "site": "Место(а)",
    "timebucket": "Тип периода",
    "units": "Единицы",
    "week": "Неделя",
    "month": "Месяц",
    "primaryUnit": "Основная",
    "secondaryUnit": "Вторичная"
  },
  "maintenances": {
    "add": "Добавить техническое обслуживание",
    "back": "назад",
    "cancel": "Отмена",
    "createSuccess": "Техническое обслуживание успешно создано",
    "createTitle": "Создать техническое обслуживание",
    "delete": "Удалить",
    "deleteDescription": "Это действие необратимо",
    "deleteQuestion": "Это навсегда удалит это техническое обслуживание. Продолжить?",
    "deleteSuccess": "Техническое обслуживание успешно удалено",
    "editTitle": "Редактировать техническое обслуживание",
    "endDate": "Дата окончания",
    "maintenanceDetails": "Детали технического обслуживания",
    "name": "Имя",
    "noData": "Техническое обслуживание не найдено",
    "notFound": "Техническое обслуживание не найдено",
    "percentageOff": "Скидка в процентах",
    "requiredError": "Это поле обязательно",
    "requiredNumberError": "Пожалуйста, введите допустимое число",
    "save": "Сохранить",
    "search": "Поиск по имени",
    "startDate": "Дата начала",
    "title": "Все технические обслуживания",
    "updateSuccess": "Техническое обслуживание успешно обновлено"
  },
  "products": {
    "add": "Добавить продукт",
    "addPromotion": "Добавить акцию",
    "addSecondaryUnit": "Добавить вторичную единицу",
    "addSimilarProducts": "Добавить похожий продукт",
    "addSuppliers": "Добавить поставщиков",
    "addTag": "Добавить тег",
    "averageDelays": "покрытие задержек",
    "source": "Источник",
    "bufferLeadTimeTip": "Заказы размещаются заранее перед требованиями",
    "bufferLevel": "Уровень буфера",
    "bufferLevelTip": "Уровень буфера Flowlity будет влиять на уровень вашего буферного запаса. Чем ниже уровень буфера, тем ниже будет ваш минимальный уровень. Чем выше уровень буфера, тем выше будет ваш минимальный уровень.",
    "bufferPolicy": "Политика буфера",
    "bufferStockTip": "Минимальный запас учитывает задержки поставщиков",
    "cancel": "Отмена",
    "cases": "случаи",
    "coeff": "Коэффициент",
    "coverPercentageOfRisk": "чтобы покрыть {count}% риска",
    "coverage": "Покрытие",
    "computeNewMin": "Рассчитать новый мин",
    "days": "день | день | дни",
    "defaultSimilarSelect": "Нет",
    "defaultUnit": "Основная единица",
    "delayForecastingDescription": "Рекомендуемые значения от Flowlity",
    "delete": "Удалить",
    "deleteDescription": "Вы будете автоматически перенаправлены на страницу списка после удаления.",
    "deleteProduct": "Удалить продукт",
    "deleteTitle": "Вы действительно хотите удалить этот продукт?",
    "demandBasedBuffer": "На основе спроса - Flowlity AI",
    "demandBasedBufferDescription": "Рекомендации по поставкам будут размещены на основе прогноза Flowlity и уровня запасов. Уровень буферного запаса формируется на основе неопределенностей спроса и поставок Flowlity",
    "description": "Описание",
    "discard": "Отменить",
    "discardCancelButton": "Вернуться к редактированию",
    "discardConfirmButton": "Отменить изменения",
    "discardDescription": "Вы потеряете все изменения, сделанные на текущей вкладке",
    "discardTitle": "Отменить изменения?",
    "displayUnit": "Единица отображения",
    "editTitle": "Редактировать детали продукта",
    "emptyDelayForecasting": "Нет рекомендаций по задержкам, требуется больше данных для генерации",
    "emptyError": "Не может быть пустым",
    "events": "События",
    "externalIntegration": "Эти данные интегрированы из внешней системы",
    "selectedProductsUseDifferentUnits": "Выбранные продукты используют разные единицы",
    "inventoryStrategies": "Стратегия инвентаризации",
    "myStockMin": "Flowlity AI + Мой мин",
    "myStockMinDescription": "Рекомендации по поставкам основаны на прогнозе Flowlity AI и уровне запасов. Уровень буферного запаса определяется пользователем",
    "fullHorizon": "Полный горизонт",
    "manualPeriod": "Ручной период",
    "manualHorizon": "Ручной горизонт",
    "mts": "Производство на склад",
    "mtsDescription": "Рекомендации по поставкам основаны на уровне буферного запаса",
    "mto": "Производство на заказ",
    "mtoDescription": "Рекомендации по поставкам основаны на твердом спросе. Буферный запас отсутствует",
    "flowlityAi": "Flowlity AI",
    "internalCustomer": "Внутренний клиент",
    "internalCustomerTooltip": "Прогнозные данные внутренних клиентов исключены из расчета прогноза продукта. Для расчета используется только твердый спрос внутренних клиентов.",
    "fixedLotSize": "Фиксированный размер партии",
    "info": "Информация",
    "infoRatio": "Соотношение",
    "initialValue": "Начальное значение - {initialBufferLevel}",
    "exactValue": "Точное значение",
    "inventoryStrategy": "Стратегия инвентаризации",
    "lockedByTacticalChanges": "Заблокировано тактическими изменениями",
    "leadTime": "Время выполнения",
    "manualStockCoverage": "Ручное покрытие запасов",
    "moq": "Минимальный объем заказа",
    "myMaxValue": "Мое максимальное значение",
    "myStockMax": "Мой максимальный запас",
    "myStockMaxTooltip": "Срабатывает предупреждение, когда уровень запасов превышает мое максимальное значение запаса. Мое максимальное значение запаса не действует как ограничение при расчете плана поставок",
    "name": "Имя",
    "next6mAvgDays": "Среднее за следующие 6 месяцев",
    "next6mAvgDaysSimulated": "Смоделированное среднее за следующие 6 месяцев",
    "next6mAvgQtySimulated": "Смоделированное среднее количество за следующие 6 месяцев",
    "noData": "Продукт не найден",
    "none": "Нет",
    "noPolicy": "Нет политики",
    "price": "Цена",
    "productionEnd": "Окончание производства",
    "productionStart": "Начало производства",
    "productLifeCycle": "Жизненный цикл продукта",
    "promotions": "Акции",
    "quantity": "Количество",
    "ratio": "Соотношение (0-100%)",
    "remove": "Удалить",
    "reorderPoint": "Точка повторного заказа",
    "reorderPointDescription": "Рекомендации по поставкам срабатывают, когда запас достигает или опускается ниже уровня точки повторного заказа. В пределах горизонта точки повторного заказа учитывается только твердый спрос (без прогноза)",
    "ropAI": "Точка повторного заказа + AI",
    "ropAIDescription": "AI динамически генерирует уровень точки повторного заказа на основе времени выполнения и прошлых потреблений",
    "salesEnd": "Конец продаж",
    "salesEndTooltip": "Эти данные интегрированы из внешней системы",
    "salesStart": "Начало продаж",
    "save": "Сохранить",
    "apply": "Применить",
    "changesApplied": "Изменения стратегии инвентаризации применены",
    "incomingChanges": "Входящие изменения:",
    "inventoryStrategyWasChanged": "Стратегия инвентаризации была изменена.",
    "saveBufferPolicyDescription": "Вы увидите изменения после следующего вычисления.",
    "saveBufferPolicyDescriptionWithOptimizeBtn": "Вы увидите изменения после следующего вычисления. Вы также можете использовать кнопку \"Оптимизировать\" в Планировании, чтобы немедленно пересчитать минимальные и рекомендованные поставки.",
    "saveBufferPolicyTitle": "Сохранить изменения конфигурации стратегии инвентаризации?",
    "saveChanges": "Сохранить изменения",
    "search": "Поиск по имени",
    "secondaryUnit": "Вторичная единица",
    "selectADate": "Выберите дату",
    "shelfLife": "Срок годности",
    "similarProducts": "Похожие продукты",
    "similarProductsSubTitle": "Добавить бывшие похожие продукты",
    "showNewMin": "Показать новый мин",
    "sourceCustomer": "Исходный клиент",
    "sourceProduct": "Исходный продукт",
    "stock": "Запас",
    "stockCoverageTitle": "Покрытие запасов, дни",
    "stockMinCoverage": "Минимальное покрытие запасов",
    "stockPolicyHelpContent": "Минимальный запас регулируется с учетом этих задержек",
    "atLeastOneProductCannotUseThisPolicy": "Как минимум один выбранный продукт не может использовать эту политику",
    "storageSites": "Места хранения",
    "strategy": "Стратегия",
    "suppliers": "Поставщики",
    "supplyBufferPolicy": "Политика буфера поставок",
    "supplyBufferPolicyNoDefaultStockTip": "Политика буфера поставок по умолчанию - \"Запас\", но ее нельзя использовать с текущей конфигурацией",
    "supplyStrategy": "Стратегия поставок",
    "tagGrouper": "Теги для группировки заказов",
    "tags": "Теги",
    "targetCustomer": "Целевой клиент",
    "time": "Время",
    "timePolicyHelpContent": "Даты доставки в заказах, размещенных заранее перед требованиями планирования.",
    "title": "Все продукты",
    "units": "Единицы",
    "useDefaultBufferPolicy": "Использовать политику по умолчанию",
    "value": "Значение",
    "withCustomer": "с клиентом",
    "mixed": "Смешанный",
    "values": "Значения",
    "allSuppliers": "Все поставщики",
    "perSupplier": "По поставщику",
    "delayCoverage": "Покрытие задержек",
    "productUseDefaultPolicy": "Продукт использует политику по умолчанию",
    "bom": "Сборочный чертеж",
    "bomParents": "Родительские продукты",
    "bomChildren": "Дочерние продукты",
    "bomSubTitle": "В родительских продуктах мы показываем значения текущей ссылки в качестве компонента в их спецификации.",
    "openPlanning": "Открыть планирование",
    "bomParentsQuantity": "Количество BOM (текущий артикул)",
    "bomParentsOffset": "Смещение BOM (текущий артикул)",
    "bomChildrenQuantity": "Количество BOM",
    "bomChildrenOffset": "Смещение BOM"
  }
}
